import { Link } from "gatsby";
import React from "react";
import ButtonLink from "../components/ButtonLink";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const NotFound404 = () => {
    return (
        <Layout>
            <Seo title="Not found - Arek Wtykło" />

            <h1 className="h1">
                Page <strong className="text__name">not found</strong>!
            </h1>

            <br />

            <p className="text__lead">
                Sorry! You can jump to{" "}
                <Link to="/" className="text__link">
                    homepage
                </Link>{" "}
                and get a fresh cup of coffee.
            </p>

            <br />
            <br />

            <p className="buttonLink__text">Here are some links that might help you to back on track.</p>

            <div className="buttonLink__wrapper">
                <ButtonLink {...{ title: "Projects", to: "/projects" }} />
                <ButtonLink {...{ title: "About me", to: "/about" }} />
                <ButtonLink {...{ title: "Contact", to: "/contact" }} />
            </div>
        </Layout>
    );
};

export default NotFound404;
